import { gql } from '@apollo/client';

const COMMON_FILEDS = gql`
  fragment CoreCommonFields on PackageOrderResponse {
    eventName
    qty
    client
    saleDate
    invoiceId
    eventId
    recipient {
      recipientName
      recipientEmail
      recipientPhone
      recipientInfoGatheringStatus
    }
    venue
    eventDate
    shipBy
    type
    statusId
    eventDateSort
    deliveryMethod
    intlShipBy
    listPrice
    eventDateChangedFlag
    externalPo
  }
`;

export const NEEDS_FULFILLMENT = gql`
  ${COMMON_FILEDS}
  query needsFulfillmentOrders(
    $page: Float
    $itemsPerPage: Float
    $invoiceId: Float
    $sortBy: String
    $sortDirection: String
    $filter: FilterInput
    $csrIdsToFilter: [Float!]
    $po: Float
    $orderId: String
    $typesToFilter: [Float!]
    $sortDateRangeBy: String
    $dateRange: [String!]
  ) {
    needsFulfillmentOrders(
      page: $page
      itemsPerPage: $itemsPerPage
      invoiceId: $invoiceId
      sortBy: $sortBy
      sortDirection: $sortDirection
      filter: $filter
      csrIdsToFilter: $csrIdsToFilter
      po: $po
      typesToFilter: $typesToFilter
      sortDateRangeBy: $sortDateRangeBy
      dateRange: $dateRange
      orderId: $orderId
    ) {
      totalPageCount
      totalPackagesCount
      orders {
        ...CoreCommonFields
        ticketGroupCode
        section
        row
        trackingNumber
        daysToShip
        ticketFulfilled
        parkingFulfilled
        ticketFulfilled
        groupId
      }
    }
  }
`;

export const NEW = gql`
  ${COMMON_FILEDS}
  query newPackageOrders(
    $page: Float
    $itemsPerPage: Float
    $invoiceId: Float
    $sortBy: String
    $sortDirection: String
    $filter: FilterInput
    $po: Float
    $sortDateRangeBy: String
    $dateRange: [String!]
    $orderId: String
  ) {
    newPackageOrders(
      page: $page
      itemsPerPage: $itemsPerPage
      invoiceId: $invoiceId
      sortBy: $sortBy
      sortDirection: $sortDirection
      filter: $filter
      po: $po
      sortDateRangeBy: $sortDateRangeBy
      dateRange: $dateRange
      orderId: $orderId
    ) {
      totalPageCount
      totalPackagesCount
      orders {
        ...CoreCommonFields
        ticketGroupCode
        section
        row
        trackingNumber
        daysToShip
        ticketFulfilled
        parkingFulfilled
        notes
        budget
        invoiceTotal
      }
    }
  }
`;

export const NEEDS_DELIVERY = gql`
  ${COMMON_FILEDS}
  query needsDelivery(
    $page: Float
    $itemsPerPage: Float
    $invoiceId: Float
    $sortBy: String
    $sortDirection: String
    $filter: FilterInput
    $csrIdsToFilter: [Float!]
    $po: Float
    $typesToFilter: [Float!]
    $sortDateRangeBy: String
    $dateRange: [String!]
    $orderId: String
  ) {
    needsDelivery(
      page: $page
      itemsPerPage: $itemsPerPage
      invoiceId: $invoiceId
      sortBy: $sortBy
      sortDirection: $sortDirection
      filter: $filter
      csrIdsToFilter: $csrIdsToFilter
      po: $po
      typesToFilter: $typesToFilter
      sortDateRangeBy: $sortDateRangeBy
      dateRange: $dateRange
      orderId: $orderId
    ) {
      totalPageCount
      totalPackagesCount
      orders {
        ...CoreCommonFields
        ticketGroupCode
        notes
        section
        row
        shipBySort
        trackingNumber
        shippingTrackingStatusId
        shippingTrackingId
        daysToShip
        saleDateSort
        ticketFulfilled
        parkingFulfilled
        carrierTypeId
        groupId
      }
    }
  }
`;

export const MERCH_DELIVERY = gql`
  ${COMMON_FILEDS}
  query merchDelivery(
    $page: Float
    $itemsPerPage: Float
    $invoiceId: Float
    $sortBy: String
    $sortDirection: String
    $filter: FilterInput
    $csrIdsToFilter: [Float!]
    $po: Float
    $typesToFilter: [Float!]
    $sortDateRangeBy: String
    $dateRange: [String!]
  ) {
    merchDelivery(
      page: $page
      itemsPerPage: $itemsPerPage
      invoiceId: $invoiceId
      sortBy: $sortBy
      sortDirection: $sortDirection
      filter: $filter
      csrIdsToFilter: $csrIdsToFilter
      po: $po
      typesToFilter: $typesToFilter
      sortDateRangeBy: $sortDateRangeBy
      dateRange: $dateRange
    ) {
      totalPageCount
      totalPackagesCount
      orders {
        ...CoreCommonFields
        ticketGroupCode
        notes
        section
        row
        shipBySort
        trackingNumber
        shippingTrackingStatusId
        shippingTrackingId
        daysToShip
        saleDateSort
        ticketFulfilled
        parkingFulfilled
        carrierTypeId
        groupId
      }
    }
  }
`;

export const PENDING_REC = gql`
  ${COMMON_FILEDS}
  query pendingRec(
    $page: Float
    $itemsPerPage: Float
    $invoiceId: Float
    $sortBy: String
    $sortDirection: String
    $filter: FilterInput
    $csrIdsToFilter: [Float!]
    $po: Float
    $typesToFilter: [Float!]
    $sortDateRangeBy: String
    $dateRange: [String!]
    $orderId: String
  ) {
    pendingRec(
      page: $page
      itemsPerPage: $itemsPerPage
      invoiceId: $invoiceId
      sortBy: $sortBy
      sortDirection: $sortDirection
      filter: $filter
      csrIdsToFilter: $csrIdsToFilter
      po: $po
      typesToFilter: $typesToFilter
      sortDateRangeBy: $sortDateRangeBy
      dateRange: $dateRange
      orderId: $orderId
    ) {
      totalPageCount
      totalPackagesCount
      orders {
        ...CoreCommonFields
        ticketGroupCode
        notes
        section
        row
        shipBySort
        trackingNumber
        saleDateSort
        ticketFulfilled
        parkingFulfilled
        daysToShip
        carrierTypeId
        groupId
      }
    }
  }
`;

export const ALL = gql`
  ${COMMON_FILEDS}
  query allOrders(
    $page: Float!
    $itemsPerPage: Float!
    $sortBy: String
    $sortDirection: String
    $filter: FilterInput
    $csrIdsToFilter: [Float!]
    $recipientNameSearch: String
    $clientSearch: String
    $typesToFilter: [Float!]
    $sortDateRangeBy: String
    $dateRange: [String!]
  ) {
    allOrders(
      page: $page
      itemsPerPage: $itemsPerPage
      sortBy: $sortBy
      sortDirection: $sortDirection
      filter: $filter
      csrIdsToFilter: $csrIdsToFilter
      recipientNameSearch: $recipientNameSearch
      clientSearch: $clientSearch
      typesToFilter: $typesToFilter
      sortDateRangeBy: $sortDateRangeBy
      dateRange: $dateRange
    ) {
      totalPageCount
      totalPackagesCount
      orders {
        ...CoreCommonFields
        ticketGroupCode
        notes
        section
        row
        shipBySort
        noFulfill
        trackingNumber
        saleDateSort
        ticketFulfilled
        parkingFulfilled
        daysToShip
        currentTab
        groupId
      }
    }
  }
`;

export const VOID = gql`
  ${COMMON_FILEDS}
  query voidOrders(
    $page: Float
    $itemsPerPage: Float
    $invoiceId: Float
    $sortBy: String
    $sortDirection: String
    $filter: FilterInput
    $po: Float
    $sortDateRangeBy: String
    $dateRange: [String!]
    $orderId: String
  ) {
    voidOrders(
      page: $page
      itemsPerPage: $itemsPerPage
      invoiceId: $invoiceId
      sortBy: $sortBy
      sortDirection: $sortDirection
      filter: $filter
      po: $po
      sortDateRangeBy: $sortDateRangeBy
      dateRange: $dateRange
      orderId: $orderId
    ) {
      totalPageCount
      totalPackagesCount
      orders {
        ...CoreCommonFields
        notes
        budget
        hoursFromSale
        invoiceTotal
        saleDateSort
        validated
        shipBySort
        groupId
      }
    }
  }
`;

export const REJECTED = gql`
  ${COMMON_FILEDS}
  query rejectedOrders(
    $page: Float
    $itemsPerPage: Float
    $invoiceId: Float
    $sortBy: String
    $sortDirection: String
    $filter: FilterInput
    $po: Float
    $sortDateRangeBy: String
    $dateRange: [String!]
    $orderId: String
  ) {
    rejectedOrders(
      page: $page
      itemsPerPage: $itemsPerPage
      invoiceId: $invoiceId
      sortBy: $sortBy
      sortDirection: $sortDirection
      filter: $filter
      po: $po
      sortDateRangeBy: $sortDateRangeBy
      dateRange: $dateRange
      orderId: $orderId
    ) {
      totalPageCount
      totalPackagesCount
      orders {
        ...CoreCommonFields
        notes
        shipBySort
        budget
        hoursFromSale
        invoiceTotal
        saleDateSort
        validated
        groupId
      }
    }
  }
`;

export const COMPLETED = gql`
  ${COMMON_FILEDS}
  query completedOrders(
    $page: Float
    $itemsPerPage: Float
    $invoiceId: Float
    $sortBy: String
    $sortDirection: String
    $filter: FilterInput
    $csrIdsToFilter: [Float!]
    $po: Float
    $typesToFilter: [Float!]
    $sortDateRangeBy: String
    $dateRange: [String!]
    $orderId: String
  ) {
    completedOrders(
      page: $page
      itemsPerPage: $itemsPerPage
      invoiceId: $invoiceId
      sortBy: $sortBy
      sortDirection: $sortDirection
      filter: $filter
      csrIdsToFilter: $csrIdsToFilter
      po: $po
      typesToFilter: $typesToFilter
      sortDateRangeBy: $sortDateRangeBy
      dateRange: $dateRange
      orderId: $orderId
    ) {
      totalPageCount
      totalPackagesCount
      orders {
        ...CoreCommonFields
        ticketGroupCode
        notes
        section
        row
        shipBySort
        trackingNumber
        saleDateSort
        ticketFulfilled
        parkingFulfilled
        carrierTypeId
        groupId
      }
    }
  }
`;

export const GET_DETAILS_BY_INVOICE_ID = gql`
  query getDetailsByinvoiceId($invoiceIds: [Float!]!) {
    packageDetailsByInvoiceId(invoiceIds: $invoiceIds) {
      type
      description
      budget
      po
      csr
      unfulfilled
      cost
      fillNotes
      delivered
      cost
      purchaseOrderId
      reconciliationStatus
      invoiceId
      packageDetailsId
      customTitle
      group
    }
  }
`;

export const GET_PACKAGE_WITH_DETAILS_BY_INVOICE_ID = gql`
  query getOnePackageWithDetails($invoiceId: Float!, $orderDetailsFilter: String) {
    getOnePackageWithDetails(invoiceId: $invoiceId, orderDetailsFilter: $orderDetailsFilter) {
      invoiceId
      eventName
      qty
      saleDate
      venue
      venueCity
      venueAddress
      venuePostalCode
      venueStateId
      stateName
      startDate
      endDate
      eventDate
      shipBy
      ticketGroupCode
      section
      row
      trackingNumber
      recipient
      alternativeName
      recipientEmail
      externalPo
      purchaseOrderId
      orderDetails {
        budget
        po
        description
        delivered
        csr
        type
        cost
        packageDetailsId
        group
        groupId
        confirmationNumber
        cartDetails {
          cartId
          packageDetailsId
          cartJsonData
          cartJsonDataLogs
          packageOrderId
          isPublished
          cardTitle
          isServiceDeclined
        }
      }
    }
  }
`;

export const GET_PACKAGE_CURRENT_TAB = gql`
  query packageCurrentTab($invoiceId: Float!) {
    packageCurrentTab(invoiceId: $invoiceId) {
      currentTab
      invoiceId
    }
  }
`;

export const GET_PACKAGE_CURRENT_TAB_BY_PO = gql`
  query packageCurrentTabByPo($po: Float!) {
    packageCurrentTabByPo(po: $po) {
      currentTab
      po
    }
  }
`;

export const GET_PACKAGE_CURRENT_TAB_BY_ORDER_ID = gql`
  query packageCurrentTabByOrderId($orderId: String!) {
    packageCurrentTabByOrderId(orderId: $orderId) {
      currentTab
      orderId
    }
  }
`;

export const GET_CART_DETAILS = gql`
  query getCartDetailsByPackageDetailsId($packageDetailsId: Float!) {
    getCartDetailsByPackageDetailsId(packageDetailsId: $packageDetailsId) {
      cartId
      packageDetailsId
      cartJsonData
      cartJsonDataLogs
      packageOrderId
      cardTitle
      typeId
    }
  }
`;

export const UPDATE_OR_CREATE_CART_DETAILS = gql`
  mutation updateOrCreateCartDetails($editCartDetailsInput: EditCartDetailsInput!, $user: String!) {
    editCartDetails(editCartDetailsInput: $editCartDetailsInput, user: $user) {
      invoiceId
      eventName
      qty
      saleDate
      venue
      venueCity
      venueAddress
      venuePostalCode
      venueStateId
      stateName
      startDate
      endDate
      eventDate
      shipBy
      ticketGroupCode
      trackingNumber
      section
      row
      orderDetails {
        budget
        po
        description
        delivered
        groupId
        csr
        type
        cost
        packageDetailsId
        group
        confirmationNumber
        cartDetails {
          cartId
          packageDetailsId
          cartJsonData
          cartJsonDataLogs
          packageOrderId
          isPublished
          cardTitle
          isServiceDeclined
        }
      }
    }
  }
`;

export const UPDATE_RESTAURANT_GOOGLE_URL = gql`
  mutation UpdateRestaurantGoogleUrl($name: String!, $fullAddress: String, $googleUrl: String) {
    updateRestaurantGoogleUrl(name: $name, fullAddress: $fullAddress, googleUrl: $googleUrl) {
      googleUrl
    }
  }
`;

export const UPDATE_OR_CREATE_FULLY_EDITABLE_CART_DETAILS = gql`
  mutation editFullyEditableCartDetails(
    $editCartDetailsInput: EditFullyEditableCartDetailsInput!
    $user: String!
  ) {
    editFullyEditableCartDetails(editCartDetailsInput: $editCartDetailsInput, user: $user) {
      invoiceId
      eventName
      qty
      saleDate
      venue
      venueCity
      venueAddress
      venuePostalCode
      venueStateId
      stateName
      startDate
      endDate
      eventDate
      shipBy
      ticketGroupCode
      section
      row
      orderDetails {
        budget
        po
        description
        delivered
        csr
        type
        cost
        packageDetailsId
        group
        cartDetails {
          cartId
          packageDetailsId
          cartJsonData
          cartJsonDataLogs
          packageOrderId
          isPublished
          cardTitle
        }
      }
    }
  }
`;

export const REVERT_CART_TO_ORIGINAL = gql`
  mutation revertCartDataToOriginal(
    $packageOrderId: Float!
    $cartId: Float!
    $fieldToUpdate: String!
  ) {
    revertCartDataToOriginal(
      packageOrderId: $packageOrderId
      cartId: $cartId
      fieldToUpdate: $fieldToUpdate
    ) {
      cartId
      packageDetailsId
      cartJsonData
      packageOrderId
    }
  }
`;

export const ADD_FILE = gql`
  mutation addFile($files: [Upload!]!, $title: String!, $packageDetailsId: Float!, $isQrCode: Boolean!, $user: String!) {
    addFile(files: $files, title: $title, packageDetailsId: $packageDetailsId, isQrCode: $isQrCode, user: $user) {
      cartId
      packageDetailsId
      cartJsonData
      packageOrderId
      cardTitle
      ticketFile {
        id
        key
        packageDetailsId
        fileName
        deliveryStatus
        isTransferable
        eTicketLink
        updatedAt
        ticketsTransferId
        errorMessage
        errorLogs
        isQrCode
      }
    }
  }
`;

export const DELETE_FILE = gql`
  mutation deleteFile($fileId: Float!, $title: String!, $packageDetailsId: Float!, $user: String!) {
    deleteFile(fileId: $fileId, title: $title, packageDetailsId: $packageDetailsId, user: $user) {
      packageDetailsId
      ticketFile {
        id
        key
        packageDetailsId
        deliveryStatus
        isTransferable
        eTicketLink
        updatedAt
        ticketsTransferId
        errorMessage
        errorLogs
      }
    }
  }
`;

export const DELETE_TRANSFER_LINK = gql`
  mutation deleteTransferLink($fileId: Float!, $packageDetailsId: Float!, $user: String!) {
    deleteTransferLink(fileId: $fileId, packageDetailsId: $packageDetailsId, user: $user) {
      packageDetailsId
      ticketFile {
        id
        key
        packageDetailsId
        deliveryStatus
        isTransferable
        eTicketLink
        updatedAt
        ticketsTransferId
        errorMessage
        errorLogs
      }
    }
  }
`;

export const DELETE_URL = gql`
  mutation deleteUrl($fileId: Float!, $title: String!, $packageDetailsId: Float!, $user: String!) {
    deleteUrl(fileId: $fileId, title: $title, packageDetailsId: $packageDetailsId, user: $user) {
      packageDetailsId
      ticketFile {
        id
        key
        packageDetailsId
        deliveryStatus
        isTransferable
        eTicketLink
        updatedAt
        ticketsTransferId
        errorMessage
        errorLogs
      }
    }
  }
`;

export const ADD_URL = gql`
  mutation addUrl(
    $eTicketLink: String!
    $title: String!
    $packageDetailsId: Float!
    $user: String!
    $isQrCode: Boolean!
  ) {
    addUrl(
      eTicketLink: $eTicketLink
      title: $title
      packageDetailsId: $packageDetailsId
      user: $user
      isQrCode: $isQrCode
    ) {
      cartId
      packageDetailsId
      cartJsonData
      packageOrderId
      cardTitle
      ticketFile {
        id
        key
        packageDetailsId
        fileName
        deliveryStatus
        isTransferable
        eTicketLink
        updatedAt
        ticketsTransferId
        errorMessage
        errorLogs
        isQrCode
      }
    }
  }
`;

export const PUBLISH_OR_UNPUBLISH_CART = gql`
  mutation publishOrUnpublichCard($packageDetailsId: Float!) {
    publishOrUnpublichCard(packageDetailsId: $packageDetailsId)
  }
`;

export const PUBLISH_OR_UNPUBLISH_ALL_CARDS = gql`
  mutation publishOrUnpublichAllCards(
    $invoiceId: Float!
    $action: String!
    $skipIfCardDeclined: Boolean
  ) {
    publishOrUnpublichAllCards(
      invoiceId: $invoiceId
      action: $action
      skipIfCardDeclined: $skipIfCardDeclined
    )
  }
`;

export const DELETE_FIELD_FROM_CARD = gql`
  mutation deleteFieldFromCard($fieldName: String!, $cartId: Float!, $fieldId: Float!) {
    deleteFieldFromCard(fieldName: $fieldName, cartId: $cartId, fieldId: $fieldId)
  }
`;

export const RENAME_CARD = gql`
  mutation renameCard($newTitle: String!, $packageDetailsId: Float!, $cartId: Float) {
    renameCard(newTitle: $newTitle, packageDetailsId: $packageDetailsId, cartId: $cartId) {
      cartId
      cardTitle
    }
  }
`;

export const REVERT_CARD_TITLE_TO_ORIGINAL = gql`
  mutation revertCardTitleToOriginal($cartId: Float!) {
    revertCardTitleToOriginal(cartId: $cartId)
  }
`;

export const ADD_ROW_TO_MERCHANDISE_CREDIT = gql`
  mutation addRowToMerchandiseCredit($packageDetailsId: Float!) {
    addRowToMerchandiseCredit(packageDetailsId: $packageDetailsId) {
      cartJsonData,
      packageDetailsId,
      cartId,
      cardTitle,
      isPublished,
      isServiceDeclined,
    }
  }
`;

export const ADD_ROW_TO_FANATIC_CREDIT = gql`
  mutation addRowToFanaticCredit($packageDetailsId: Float!, $isLink: Boolean!) {
    addRowToFanaticCredit(packageDetailsId: $packageDetailsId, isLink: $isLink) {
      cartJsonData,
      packageDetailsId,
      cartId,
      cardTitle,
      isPublished,
      isServiceDeclined,
    }
  }
`;

export const REMOVE_ROW_FROM_MERCHANDISE_CREDIT = gql`
  mutation removeRowFromMerchandiseCredit($rowId: Float!, $packageDetailsId: Float!) {
    removeRowFromMerchandiseCredit(rowId: $rowId, packageDetailsId: $packageDetailsId) {
      cartJsonData,
      packageDetailsId,
      cartId,
      cardTitle,
      isPublished,
      isServiceDeclined,
    }
  }
`;

export const UNDECLINE_SERVICE = gql`
  mutation undeclineService($packageDetailsId: Float!) {
    undeclineService(packageDetailsId: $packageDetailsId)
  }
`;

export const CREATE_NEW_CARD = gql`
  mutation createPackageDetails($cardData: CreatePackageDetailsInput!) {
    createPackageDetails(cardData: $cardData) {
      budget
      po
      description
      delivered
      csr
      type
      packageDetailsId
      group
      invoiceId
      cartDetails {
        cardTitle
        cartId
        cartJsonData
        isPublished
        isServiceDeclined
        packageDetailsId
        packageOrderId
      }
    }
  }
`;

export const REMOVE_CARD = gql`
  mutation removePackageDetails($packageDetailsId: Float!) {
    removePackageDetails(packageDetailsId: $packageDetailsId)
  }
`;

export const REMOVE_CARD_FROM_CARD_DETAILS = gql`
  mutation removeCardFromCardDetails($packageDetailsId: Float!) {
    removeCardFromCardDetails(packageDetailsId: $packageDetailsId)
  }
`;

export const GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO = gql`
  query getAvailableFiltersForPackageInfo($invoiceId: Float!) {
    getAvailableFiltersForPackageInfo(invoiceId: $invoiceId) {
      filtersList
    }
  }
`;

export const SAVE_CARDS_ORDER = gql`
  mutation saveCardIndexOnDrop($cardsOrderInput: CardsOrderInput!) {
    saveCardIndexOnDrop(cardsOrderInput: $cardsOrderInput)
  }
`;

export const RESET_CARDS_ORDER = gql`
  mutation resetCardsOrder($invoiceId: Float!) {
    resetCardsOrder(invoiceId: $invoiceId) {
      invoiceId
      eventName
      qty
      saleDate
      venue
      venueCity
      venueAddress
      venuePostalCode
      venueStateId
      stateName
      startDate
      endDate
      eventDate
      shipBy
      ticketGroupCode
      section
      row
      trackingNumber
      recipient
      recipientEmail
      orderDetails {
        budget
        po
        description
        delivered
        csr
        type
        cost
        packageDetailsId
        group
        confirmationNumber
        cartDetails {
          cartId
          packageDetailsId
          cartJsonData
          packageOrderId
          isPublished
          cardTitle
          isServiceDeclined
        }
      }
    }
  }
`;

export const GET_TICKETS_VIA_1TICKETS = gql`
  mutation getTicketInfoFromTicketsApi($invoiceId: Float!, $user: String!) {
    getTicketInfoFromTicketsApi(invoiceId: $invoiceId, user: $user) {
      status
      ticket {
        id
        packageDetailsId
        fileName
        isTransferable
        deliveryStatus
        updatedAt
        eTicketLink
        packagePoId
        ticketsTransferId
        errorMessage
        errorLogs
      }
    }
  }
`;

export const TRANSFER_TICKET = gql`
  mutation transferTickets($invoiceId: Float!) {
    transferTickets(invoiceId: $invoiceId) {
      status
      ticket {
        id
        packageDetailsId
        fileName
        isTransferable
        deliveryStatus
        updatedAt
        eTicketLink
        packagePoId
        ticketsTransferId
        errorMessage
        errorLogs
      }
    }
  }
`;

export const GET_FILE_DOWNLOAD_LINK = gql`
  query getFileDownloadLink($id: Float!) {
    getFileDownloadLink(id: $id)
  }
`;

export const GET_FILE_BASE64 = gql`
  query getFile($id: Float!) {
    getFile(id: $id)
  }
`;

export const GET_TICKET_TYPES_BY_COMPONENT_TYPE = gql`
  query GetTicketTypesByComponentType($componentType: String!) {
    getTicketTypesByComponentType(componentType: $componentType) {
      id
      deliveryType
      componentType
      componentTypeId
      groupType
      groupId
      description
      descriptionBeforeSent
      descriptionAfterSent
    }
  }
`;

export const GET_TICKET_TYPES = gql`
  query GetTicketTypes {
    getTicketTypes {
      id
      deliveryType
      componentType
      componentTypeId
      groupType
      description
      descriptionBeforeSent
      descriptionAfterSent
    }
  }
`;

export const GET_CARD_DESCRIPTIONS = gql`
  query getCardDescriptions {
    getCardDescriptions {
      id
      componentType
      groupId
      description
      descriptionNotPublished
      descriptionPublished
    }
  }
`;

export const GET_CARD_DESCRIPTION_BY_GROUP_ID = gql`
  query getCardDescriptionByGroupId($groupId: Float!) {
    getCardDescriptionByGroupId(groupId: $groupId) {
      id
      componentType
      groupId
      description
      descriptionNotPublished
      descriptionPublished
    }
  }
`;

export const GET_OPENTABLE_RESERVATION = gql`
  query GetReservationByInvoiceId($invoiceId: Float!) {
    getReservationByInvoiceId(invoiceId: $invoiceId) {
      success
      packagesTotal
      successfulReservations
      failedReservations
    }
  }
`;

export const GET_ALL_PACKAGE_DETAIL_GROUPS = gql`
  query getAllPackageDetailGroups {
    getAllPackageDetailGroups {
      packageDetailGroupId
      groupName
    }
  }
`;

export const GET_CART_DETAILS_LOGS = gql`
  query getCartDetailsLogs($packageDetailsId: Float!) {
    getCartDetailsLogs(packageDetailsId: $packageDetailsId) {
      packageDetailsId,
      cartJsonDataLogs,
      cartJsonDataFullLogs,
    }
  }
`;
