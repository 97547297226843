import React from 'react';
import { Col, Row } from 'reactstrap';
import TransferTypeButtons from '../../components/Buttons/TransferTypeButtons';
import { ICartInfoProps, ICartSchema, Logs } from '../../utils/interfaces';
import CartLayout from './CartLayout';
import './packageInfo.scss';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_CART_DETAILS_LOGS } from '../../queries/vip-workflow';
import { renderFieldChanges } from '../../components/Popovers/FieldChangesPopover';

const Cart = ({
  cartSchemaConfig,
  cartData,
  airportTransferType,
  setAirportTransferType,
  vegasLimoTransferType,
  setVegasLimoTransferType,
  dataKey,
  isCartInfoOpenedId,
  handleOpenDetails,
  parsedCardDetails,
  cartInfoId,
  handleOpenLearnMore,
  isLearnMoreOpenedId,
  selectedType,
  setSelectedType,
  orderDetailsArray,
}: any) => {
  if (dataKey === 'Airport Transfer') {
    const parsedAirportTransfer = JSON.parse(cartData.cartDetails.cartJsonData);

    return (
      <>
        {parsedAirportTransfer.transfer1 && parsedAirportTransfer.transfer2 ? (
          <TransferTypeButtons
            airportTransferType={airportTransferType}
            setAirportTransferType={setAirportTransferType}
            transfer1Date={parsedAirportTransfer.transfer1.date1}
            transfer2Date={parsedAirportTransfer.transfer2.date2}
          />
        ) : null}
        <div className="mt-4">
          {cartSchemaConfig?.map((transferSchemaConfig: ICartSchema, id: number) => {
            const isCardDetailsOpened =
              transferSchemaConfig.cartInfo &&
              cartData.cartDetails &&
              isCartInfoOpenedId.includes(
                cartData.cartDetails?.packageDetailsId &&
                  (parsedAirportTransfer?.transferId || parsedCardDetails?.transferId),
              );

            if (isCardDetailsOpened) {
              return Object.entries(transferSchemaConfig).map(
                ([cardDetailsKey, cardDetailsConfig]: any, idx) => {
                  return (
                    <CartLayout
                      schemaConfig={cardDetailsConfig}
                      cartData={cartData}
                      isCartInfoOpenedId={isCartInfoOpenedId}
                      handleOpenDetails={handleOpenDetails}
                      parsedCardDetails={
                        parsedAirportTransfer.transfer1 && parsedAirportTransfer.transfer2
                          ? parsedCardDetails
                          : parsedAirportTransfer
                      }
                      cartInfoId={cartInfoId}
                      key={idx}
                      dataKey={dataKey}
                      cartSchemaConfig={cartSchemaConfig}
                      transferType={airportTransferType}
                    />
                  );
                },
              );
            }

            if (transferSchemaConfig.skipInCart) {
              return;
            }

            return (
              <CartLayout
                schemaConfig={transferSchemaConfig}
                cartData={cartData}
                isCartInfoOpenedId={isCartInfoOpenedId}
                handleOpenDetails={handleOpenDetails}
                parsedCardDetails={
                  parsedAirportTransfer.transfer1 && parsedAirportTransfer.transfer2
                    ? parsedCardDetails
                    : parsedAirportTransfer
                }
                cartInfoId={cartInfoId}
                dataKey={dataKey}
                key={id}
                cartSchemaConfig={cartSchemaConfig}
                transferType={airportTransferType}
              />
            );
          })}
        </div>
      </>
    );
  }
  
  if (dataKey === 'Vegas Limo Transfer') {
    const parsedVegasLimoTransfer = JSON.parse(cartData.cartDetails.cartJsonData);
    return (
      <>
        {parsedVegasLimoTransfer.transfer1 && parsedVegasLimoTransfer.transfer2 ? (
          <TransferTypeButtons
            vegasLimoTransferType={vegasLimoTransferType}
            setVegasLimoTransferType={setVegasLimoTransferType}
            transfer1Date={parsedVegasLimoTransfer.transfer1.pickUp1}
            transfer2Date={parsedVegasLimoTransfer.transfer2.pickUp2}
          />
        ) : null}
        <div className="mt-4">
          {cartSchemaConfig?.map((transferSchemaConfig: any, id: number) => {
            const isCardDetailsOpened =
              transferSchemaConfig.cartInfo &&
              cartData.cartDetails &&
              isCartInfoOpenedId.includes(
                cartData.cartDetails?.packageDetailsId &&
                  (parsedVegasLimoTransfer?.transferId || parsedCardDetails?.transferId),
              );

            if (isCardDetailsOpened) {
              return Object.entries(transferSchemaConfig).map(
                ([cardDetailsKey, cardDetailsConfig]: any, idx) => {
                  return (
                    <CartLayout
                      schemaConfig={cardDetailsConfig}
                      cartData={cartData}
                      isCartInfoOpenedId={isCartInfoOpenedId}
                      handleOpenDetails={handleOpenDetails}
                      parsedCardDetails={
                        parsedVegasLimoTransfer.transfer1 && parsedVegasLimoTransfer.transfer2
                          ? parsedCardDetails
                          : parsedVegasLimoTransfer
                      }
                      cartInfoId={cartInfoId}
                      key={idx}
                      dataKey={dataKey}
                      cartSchemaConfig={cartSchemaConfig}
                      transferType={vegasLimoTransferType}
                    />
                  );
                },
              );
            }

            if (transferSchemaConfig.skipInCart) {
              return;
            }

            return (
              <CartLayout
                schemaConfig={transferSchemaConfig}
                cartData={cartData}
                isCartInfoOpenedId={isCartInfoOpenedId}
                handleOpenDetails={handleOpenDetails}
                parsedCardDetails={
                  parsedVegasLimoTransfer.transfer1 && parsedVegasLimoTransfer.transfer2
                    ? parsedCardDetails
                    : parsedVegasLimoTransfer
                }
                cartInfoId={cartInfoId}
                dataKey={dataKey}
                key={id}
                cartSchemaConfig={cartSchemaConfig}
                transferType={vegasLimoTransferType}
              />
            );
          })}
        </div>
      </>
    );
  }

  if (
    dataKey === 'MLB Card' ||
    dataKey === 'NBA Card' ||
    dataKey === 'NHL Card' ||
    dataKey === 'Multi-day Gift Card'
  ) {
    return (
      <div
        className="d-flex flex-wrap"
        style={{ marginTop: '6px', marginLeft: '-15px', marginRight: '-15px' }}
      >
        {parsedCardDetails.length &&
          parsedCardDetails.map((item: any, idx: any) => {
            return cartSchemaConfig?.map((field: any, id: number) => {
              if (item.hasOwnProperty(field.name)) {
                if (field.name === 'notes' || field.name === 'description') {
                  return (
                    <React.Fragment key={id}>
                      <Col xs="6" style={field.style}>
                        <span>{field.label}</span>
                        {(cartData?.cartDetails?.cartId || cartData?.cartDetails?.isPublished) && renderFieldChanges({config: field, logs: cartData.cartDetails.cartJsonDataLogs, index: idx, packageDetailsId: cartData.cartDetails.packageDetailsId})}
                      </Col>
                      <Col xs="6" style={field.style}>
                        <span className="">
                          {item[field.name] ? item[field.name] : field.defaultValue}
                        </span>
                      </Col>
                    </React.Fragment>
                  );
                }

                return (
                  <Col xs="6" style={field.style} key={id}>
                    <span>
                      {field.label}{' '}
                      {(cartData?.cartDetails?.cartId || cartData?.cartDetails?.isPublished) && renderFieldChanges({config: field, logs: cartData.cartDetails.cartJsonDataLogs, index: idx, packageDetailsId: cartData.cartDetails.packageDetailsId})} 
                      {' : '}
                    </span>
                    <span>{item[field.name] ? item[field.name] : field.defaultValue}</span>
                  </Col>
                );
              }
            });
          })}
      </div>
    );
  }
  
  // if (dataKey === 'Fanatics Credit') {
  //   const isURLType = parsedCardDetails.some((item: any) => 'eLink' in item);
   
  //   return (
  //     <div className="d-flex flex-wrap" style={{ marginTop: '6px', marginLeft: '-15px', marginRight: '-15px' }}>
  //       {parsedCardDetails.length &&
  //         parsedCardDetails.map((item: any, index: number) => (
  //           <React.Fragment key={index}>
  //             {cartSchemaConfig.map((field: any, id: number) => {
  //               if (item.hasOwnProperty(field.name)) {
  //                 if (field.name === 'description') {
  //                   const description = item[field.name].includes('${or}') ? (selectedType === 'eLink' ? item[field.name]?.split('${or}')[1] : item[field.name]?.split('${or}')[0]) : item[field.name];
  //                   return (
  //                     <React.Fragment key={id}>
  //                       <Col xs="12" style={field.style}>
  //                         <span>{field.label}</span>
  //                       </Col>
  //                       <Col xs="12" style={field.style}>
  //                         <span className="">
  //                           {item[field.name] ? description : field.defaultValue}
  //                         </span>
  //                       </Col>
  //                     </React.Fragment>
  //                   );
  //                 }
  //               }
  //               return null;
  //             })}

  //             {cartSchemaConfig.map((field: any, id: number) => {
  //               if (item.hasOwnProperty(field.name)) {
  //                 if (field.name === 'eLink' && selectedType === 'eLink') {
  //                   return (
  //                     <Col xs="12" style={field.style} key={id}>
  //                       <span>{field.label}: </span>
  //                       <a href={item[field.name] ? item[field.name] : field.defaultValue} target="_blank" rel="noreferrer">
  //                         {item[field.name] ? item[field.name] : field.defaultValue}
  //                       </a>
  //                     </Col>
  //                   );
  //                 } 
                  
  //                 if ((field.name === 'eGiftCode' || field.name === 'PIN') && selectedType === 'eGiftCode') {
  //                   return (
  //                     <Col xs="6" style={field.style} key={id}>
  //                       <span>
  //                         {field.label}: {item[field.name] ? item[field.name] : field.defaultValue}
  //                       </span>
  //                     </Col>
  //                   );
  //                 }
  //               }
  //               return null;
  //             })}
  //           </React.Fragment>
  //         ))}
  //     </div>
  //   );
  // }
  if (dataKey === 'Fanatics Credit') {
    // const isURLType = parsedCardDetails.some((item: any) => 'eLink' in item);
    return (
      <div className="d-flex flex-wrap" style={{ marginTop: '6px', marginLeft: '-15px', marginRight: '-15px' }}>
        {parsedCardDetails.length &&
          parsedCardDetails.map((item: any, index: number) => (
            <React.Fragment key={index}>
              {cartSchemaConfig.map((field: any, id: number) => {
                if (item.hasOwnProperty(field.name)) {
                  if (field.name === 'description') {
                    const description = item[field.name].includes('${or}') ? (selectedType === 'eLink' ? item[field.name]?.split('${or}')[1] : item[field.name]?.split('${or}')[0]) : item[field.name];
                    return (
                      <React.Fragment key={id}>
                        <Col xs="12" style={field.style}>
                          <span>{field.label}</span>
                          {(cartData?.cartDetails?.cartId || cartData?.cartDetails?.isPublished) && renderFieldChanges({config: field, logs: cartData.cartDetails.cartJsonDataLogs, index, packageDetailsId: cartData.cartDetails.packageDetailsId})}
                        </Col>
                        <Col xs="12" style={field.style}>
                          <span className="">
                            {item[field.name] ? description : field.defaultValue}
                          </span>
                        </Col>
                      </React.Fragment>
                    );
                  }
                }
                return null;
              })}

              {cartSchemaConfig.map((field: any, id: number) => {
                if (item.hasOwnProperty(field.name)) {
                  if (field.name === 'eLink' && selectedType === 'eLink') {
                    return (
                      <Col xs="12" style={field.style} key={id}>
                        <span>{field.label} {(cartData?.cartDetails?.cartId || cartData?.cartDetails?.isPublished) && renderFieldChanges({config: field, logs: cartData.cartDetails.cartJsonDataLogs, index, packageDetailsId: cartData.cartDetails.packageDetailsId})}{' : '}</span>
                        <a href={item[field.name] ? item[field.name] : field.defaultValue} target="_blank" rel="noreferrer">
                          {item[field.name] ? item[field.name] : field.defaultValue}
                        </a>
                      </Col>
                    );
                  } if ((field.name === 'eGiftCode' || field.name === 'PIN') && selectedType === 'eGiftCode') {
                    return (
                      <Col xs="6" style={field.style} key={id}>
                        <span>{field.label} {(cartData?.cartDetails?.cartId || cartData?.cartDetails?.isPublished) && renderFieldChanges({config: field, logs: cartData.cartDetails.cartJsonDataLogs, index, packageDetailsId: cartData.cartDetails.packageDetailsId})}{' : '}</span> 
                        <span>
                          {item[field.name] ? item[field.name] : field.defaultValue}
                        </span>
                      </Col>
                    );
                  }
                }
                return null;
              })}
            
            </React.Fragment>
          ))}
      </div>
    );
  }

  if (dataKey === 'City Pass') {
    return (
      <div style={{ marginTop: '20px' }}>
        {cartSchemaConfig?.map((schemaConfig: any, id: number) => {
          const isCardDetailsOpened =
            schemaConfig.cartInfo &&
            cartData.cartDetails &&
            isCartInfoOpenedId.includes(cartData.cartDetails.packageDetailsId);

          if (
            schemaConfig.hideIfEmpty &&
            !parsedCardDetails?.[schemaConfig.name as keyof typeof cartData]
          ) {
            return;
          }

          if (isCardDetailsOpened) {
            const attendeeNames = Array.isArray(parsedCardDetails?.attendeeNames)
            ? parsedCardDetails?.attendeeNames
            : JSON.parse(parsedCardDetails?.attendeeNames || '[]');

            return attendeeNames?.map(
              (attendee: { attendeeName: string }, id: number) => {
                return Object.entries(schemaConfig).map(
                  ([cartDetailsKey, cartDetailsConfig]: any, idx) => {
                    if (cartDetailsKey === 'cartInfo' || cartDetailsKey === 'checkPending') return;

                    return (
                      <CartLayout
                        schemaConfig={cartDetailsConfig}
                        cartData={cartData}
                        isCartInfoOpenedId={isCartInfoOpenedId}
                        handleOpenDetails={handleOpenDetails}
                        parsedCardDetails={attendee}
                        cartInfoId={id}
                        key={idx}
                        dataKey={dataKey}
                        cartSchemaConfig={cartSchemaConfig}
                      />
                    );
                  },
                );
              },
            );
          }

          return (
            <Row style={{ ...schemaConfig.style }} key={id}>
              {schemaConfig?.label && 
              <Col
                xs={schemaConfig.alignToLeft ? '11' : '6'}
                className={schemaConfig.bold ? 'd-flex font-weight-bold' : 'd-flex'}
              >
                <div>
                  <span style={{ whiteSpace: 'pre-line' }}>{schemaConfig.label}</span>
                  {(cartData?.cartDetails?.cartId || cartData?.cartDetails?.isPublished) && renderFieldChanges({config: schemaConfig, logs: cartData.cartDetails.cartJsonDataLogs, packageDetailsId: cartData.cartDetails.packageDetailsId})}
                </div>
              </Col>}
              {schemaConfig?.name && schemaConfig?.name !== 'attendeeNames' ? (
                <Col>
                  {schemaConfig.isTextContainsLink ? (
                    <>
                      <span
                        style={{ whiteSpace: 'pre-line' }}
                        dangerouslySetInnerHTML={{
                          __html: parsedCardDetails?.[schemaConfig.name as any],
                        }}
                      ></span>
                    </>
                  ) : (
                    <>
                      <span>
                        {parsedCardDetails?.[schemaConfig.name as keyof typeof parsedCardDetails]
                          ? parsedCardDetails?.[schemaConfig.name as keyof typeof parsedCardDetails]
                          : schemaConfig.defaultValue}
                      </span>
                    </>
                  )}
                </Col>
              ) : null}
              {schemaConfig.showMoreButton ? (
                <Col>
                  <button
                    style={{ border: 'none', background: 'none', marginLeft: '15px' }}
                    onClick={(e) => handleOpenDetails(e, cartData.cartDetails.packageDetailsId)}
                  >
                    {isCartInfoOpenedId.includes(
                      (cartData.cartDetails?.packageDetailsId && parsedCardDetails?.transferId) ||
                        cartData.cartDetails?.packageDetailsId ||
                        parsedCardDetails?.transferId,
                    ) ? (
                      <span style={{ color: '#337ab7', textDecoration: 'underline' }}>Hide</span>
                    ) : (
                      <span style={{ color: '#337ab7', textDecoration: 'underline' }}>
                        Show More
                      </span>
                    )}
                  </button>
                </Col>
              ) : null}
              {schemaConfig.render ? <Col>{schemaConfig.render()}</Col> : null}
            </Row>
          );
        })}
      </div>
    );
  }
  
  if (dataKey === 'Rideshare Pass') {
    return (
      <div style={{ marginTop: '20px' }}>
        {cartSchemaConfig?.map((schemaConfig: any, id: number) => {
          const isCardDetailsOpened =
            schemaConfig.cartInfo &&
            cartData.cartDetails &&
            isCartInfoOpenedId.includes(cartData.cartDetails.packageDetailsId);

          if (
            schemaConfig.hideIfEmpty &&
            !parsedCardDetails?.[schemaConfig.name as keyof typeof cartData]
          ) {
            return;
          }

          return (
            <Row style={{ ...schemaConfig.style }} key={id}>
              <Col
                xs={schemaConfig.alignToLeft ? '11' : '6'}
                className={schemaConfig.bold ? 'd-flex font-weight-bold' : 'd-flex'}
              >
                <div>
                  <span style={{ whiteSpace: 'pre-line' }}>{schemaConfig.label}</span>
                  {(cartData?.cartDetails?.cartId || cartData?.cartDetails?.isPublished) && renderFieldChanges({config: schemaConfig, logs: cartData.cartDetails.cartJsonDataLogs, packageDetailsId: cartData.cartDetails.packageDetailsId})}
                </div>
              </Col>
              {schemaConfig.name !== 'attendeeNames' ? (
                <Col>
                  {schemaConfig.isTextContainsLink ? (
                    <span
                      style={{ whiteSpace: 'pre-line' }}
                      dangerouslySetInnerHTML={{
                        __html: parsedCardDetails?.[schemaConfig.name as any],
                      }}
                    ></span>
                  ) : (
                    <span>
                      {parsedCardDetails?.[schemaConfig.name as keyof typeof parsedCardDetails]
                        ? parsedCardDetails?.[schemaConfig.name as keyof typeof parsedCardDetails]
                        : schemaConfig.defaultValue}
                    </span>
                  )}
                </Col>
              ) : null}
              {schemaConfig.showMoreButton ? (
                <Col>
                  <button
                    style={{ border: 'none', background: 'none', marginLeft: '15px' }}
                    onClick={(e) => handleOpenDetails(e, cartData.cartDetails.packageDetailsId)}
                  >
                    {isCartInfoOpenedId.includes(
                      (cartData.cartDetails?.packageDetailsId && parsedCardDetails?.transferId) ||
                        cartData.cartDetails?.packageDetailsId ||
                        parsedCardDetails?.transferId,
                    ) ? (
                      <span style={{ color: '#337ab7', textDecoration: 'underline' }}>Hide</span>
                    ) : (
                      <span style={{ color: '#337ab7', textDecoration: 'underline' }}>
                        Show More
                      </span>
                    )}
                  </button>
                </Col>
              ) : null}
              {schemaConfig.render ? <Col>{schemaConfig.render()}</Col> : null}
            </Row>
          );
        })}
      </div>
    );
  }

  return (
    <>
      {cartSchemaConfig?.map((schemaConfig: any, id: number) => {
        const isCardDetailsOpened =
          schemaConfig.cartInfo &&
          cartData.cartDetails &&
          isCartInfoOpenedId.includes(cartData.cartDetails.packageDetailsId);

        const isLearnMoreOpened =
          schemaConfig.learnMore &&
          isLearnMoreOpenedId.includes(cartData.cartDetails.packageDetailsId);

        if (schemaConfig.skipInCart) {
          return;
        }

        if (
          schemaConfig.hideIfEmpty &&
          !parsedCardDetails?.[schemaConfig.name as keyof typeof cartData]
        ) {
          return;
        }

        if (isCardDetailsOpened) {
          return Object.entries(schemaConfig).map(
            ([cartDetailsKey, cartDetailsConfig]: any, idx) => {
              if (cartDetailsKey === 'cartInfo') return;

              return (
                <CartLayout
                  schemaConfig={cartDetailsConfig}
                  cartData={cartData}
                  isCartInfoOpenedId={isCartInfoOpenedId}
                  handleOpenDetails={handleOpenDetails}
                  parsedCardDetails={parsedCardDetails}
                  cartInfoId={cartInfoId}
                  key={idx}
                  dataKey={dataKey}
                  cartSchemaConfig={cartSchemaConfig}
                />
              );
            },
          );
        }

        if (isLearnMoreOpened) {
          return (
            <CartLayout
              schemaConfig={schemaConfig}
              cartData={cartData}
              isCartInfoOpenedId={isCartInfoOpenedId}
              handleOpenDetails={handleOpenDetails}
              parsedCardDetails={parsedCardDetails}
              cartInfoId={cartInfoId}
              handleOpenLearnMore={handleOpenLearnMore}
              isLearnMoreOpenedId={isLearnMoreOpenedId}
              isLearnMoreOpened={isLearnMoreOpened}
              key={id}
              dataKey={dataKey}
              cartSchemaConfig={cartSchemaConfig}
            />
          );
        }

        return (
          <CartLayout
            schemaConfig={schemaConfig}
            cartData={cartData}
            isCartInfoOpenedId={isCartInfoOpenedId}
            handleOpenDetails={handleOpenDetails}
            parsedCardDetails={parsedCardDetails}
            cartInfoId={cartInfoId}
            handleOpenLearnMore={handleOpenLearnMore}
            isLearnMoreOpenedId={isLearnMoreOpenedId}
            key={id}
            dataKey={dataKey}
            cartSchemaConfig={cartSchemaConfig}
          />
        );
      })}
    </>
  );
};

export default Cart;
